
/*$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;
*/

$theme-colors: (
  "black": #212529,
  "realme": #FFC915,
  "oppo": #098A5A 
);

$primary: #098A5A;
$link-color: #212529;
$link-decoration: none;
$link-hover-color:  #FFC915;
$link-hover-decoration: none;   
$custom-select-indicator: url("data:image/svg+xml,<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='sort-down' class='svg-inline--fa fa-sort-down fa-4x' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path fill='currentColor' d='M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z'></path></svg>");

$border-radius-lg: 1.2rem;
$input-btn-padding-x-lg: 1.4rem;
$btn-font-size-lg: 1.2rem;
$modal-content-border-radius: 0;

$btn-font-size: 1rem;
@import '~bootstrap/scss/bootstrap.scss';
@import "../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss";

@include media-breakpoint-down(md){
  .brand{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}
}