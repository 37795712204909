@font-face {
  font-family: OPPOSans;
  font-weight: normal;
  src: url(../static/OPPOSans-Regular.otf) format('opentype');
}

@font-face {
  font-family: OPPOSans;
  font-weight: bold;
  src: url(../static/OPPOSans-Bold.otf) format('opentype');
}

@font-face {
  font-family: MyriadPro;
  font-weight: normal;
  src: url(../static/MyriadPro-Regular.woff);
}

@font-face {
  font-family: MyriadPro;
  font-weight: bold;
  src: url(../static/MyriadPro-Semibold.woff);
}

body {
  font-family: MyriadPro, 'Times New Roman', Times, serif;
  background-color: #f6f6f6;
}

.button-oppo {
  background-color: #07b570;
  border-radius: 28px;
  border: 1px solid #18ab29;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-size: 17px;
  padding: 10px 31px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #2f6627;
}
.button-oppo:hover {
  background-color: #046a38;
  color: #ffffff;
}
.button-oppo:active {
  position: relative;
  top: 1px;
}

.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
  top: 12px !important;
}

.btn-light {
  background-color: #fff;
  border: 1px solid #ced4da !important;
}

.form-fix {
  padding-left: 12px !important;
}

.form-fix::placeholder {
  color: #999999;
}

.dropdown-item.active {
  background-color: #07b570;
}

button span {
  font-size: 1.6rem;
}

button:focus,
a:focus,
a.btn:focus {
  outline: 0;
  -webkit-appearance: none;
  box-shadow: none;
}

a,
a:hover {
  color: #000;
  text-decoration: none;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/*   -----Nav----start   */

.modal-open .navbar {
  overflow-y: scroll;
}
.navbar-brand {
  padding: 7.5px 0;
}
.navbar-brand img {
  width: 142px;
  height: 23px;
}

.nav-item {
  font-family: OPPOSans, serif, 'Times New Roman', Times;
  padding: 0 0.4rem;
}

.nav-item a {
  position: relative;
  color: rgba(255, 255, 255, 0.4);
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 400;
  line-height: 48px;
}

.nav-item a::after {
  content: '';
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  position: absolute;
  bottom: -7px;
  left: 0;
  transform: scaleX(0);
  transform-origin: center;
  transition: all 600ms ease-in-out;
}

.nav-item a:hover,
.nav-item a:focus {
  color: rgba(255, 255, 255, 1);
  transition: all 600ms ease-in-out;
}

/* 992px */
@media only screen and (max-width: 992px) {
  .nav-item a {
    line-height: normal;
  }
}

@media only screen and (min-width: 992px) {
  .nav-item a:hover::after {
    transform: scaleX(1);
  }
}

/*   -----Nav----end   */
.landing {
  position: relative;
  width: 100%;
  height: 540px;
  display: table;
  z-index: -1;
}

@media only screen and (max-width: 768px) {
  .landing {
    display: none;
  }
}

.home-wrap {
  clip: rect(0, auto, auto, 0);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.home-inner {
  background-image: url('../static/banner_repair.png');
  position: fixed;
  height: 540px;
  width: 100%;
  background-size: cover;
  background-position: center;
  transform: translateZ(0);
  will-change: transform;
  display: table;
}

@media only screen and (max-width: 540px) {
  .home-inner {
    background-image: url('../static/banner_repair_sm.png');
  }
}

/*   ----button-white----start     */
.button-white {
  box-shadow: inset 0px 1px 0px 0px #ffffff;
  background: linear-gradient(to bottom, #ffffff 5%, #f6f6f6 100%);
  background-color: #ffffff;
  border-radius: 42px;
  border: 1px solid #dcdcdc;
  display: inline-block;
  cursor: pointer;
  color: #666666;
  font-family: Arial;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 24px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #ffffff;
}
.button-white:hover {
  background: linear-gradient(to bottom, #f6f6f6 5%, #ffffff 100%);
  color: #000 !important;
  text-decoration: none !important;
  background-color: #f6f6f6;
}
.button-white:active {
  position: relative;
  color: #000 !important;
  text-decoration: none !important;
  top: 1px;
}

/*   ----button-white----end     */

.content {
  background-color: #f6f6f6;
}

/*  ----main-select-content----start */
.select-service .row .col {
}
.select-item {
  background-color: #fff;

  transition: box-shadow 0.8s ease;
}

.select-item:hover {
  -webkit-box-shadow: 0px 0px 14px -1px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 0px 14px -1px rgba(0, 0, 0, 0.6);
}
/*  ----main-select-content----end */
/*  ----contact-us----start    */
.contact-us {
}

.contact-us .right img {
}
/*  ----contact-us----end   */

/*  ----footer----start    */
footer {
  border-top: 2px solid #eee;
  background-color: #f6f6f6;
  padding-top: 56px;
  font-family: OPPOSans, serif, 'Times New Roman', Times;
}

ul {
  padding-left: 0;
}
ul li {
  list-style-type: none;
  padding: 0.3rem 0;
}

footer ul li h5 {
  color: rgba(255, 255, 255, 0.78);
  font-size: 14px;
}

footer ul li a {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.48);
}

footer ul li a:hover {
  color: rgba(255, 255, 255, 0.48);
}

footer p {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.48);
}

footer p a {
  color: #2b81af;
}

.footer-tas {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.48);
  margin-right: 24px;
  line-height: 36px;
}

.social-media-items {
  margin-top: 36px;
  margin-bottom: 36px;
}

.social-media-items span a {
  color: #666666;
  padding: 0 0.3rem;
  font-size: 1.35rem;
}
/*  ----footer----end   */

/*   ----reminder----start  */

.reminder {
  font-size: 0.9rem;
}

/*   ----reminder----end  */

/* ----date picker----*/
.modal-body span {
  border: 1px solid #eee;
  display: inline-block;
  border-radius: 50%;
  padding-top: 14px;
  width: 50px;
  height: 50px;
  text-align: center;
  cursor: pointer;
}

.modal-body span:not(:last-child) {
  margin-right: 0.5rem;
}

.date-selector .col {
  padding: 0;
  text-align: center;
}

.date-selector .active {
  border-color: #07b570;
}

/* ----date picker end ----*/

#overlay {
  position: absolute; /* Sit on top of the page content */
  display: none;
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; /* Black background with opacity */
  z-index: 999; /* Specify a stack order in case you're using a different order for other elements */
}

#overlay.active {
  display: block;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
}
